import { createContext, useContext, useState, useEffect } from 'react'
import axios from '../api/axios'
import { useCart } from './CartContext'

// Create the Order context
const OrderContext = createContext()

export const useOrder = () => useContext(OrderContext)

export const OrderProvider = ({ children }) => {
  const [wilayas, setWilayas] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)

  const { clearCart } = useCart()

  const createOrder = async (orderData) => {
    try {
      setIsLoading(true)
      setIsError(false)
      console.log('Creating order with data:', orderData)
      const { data } = await axios.post('/orders', orderData)
      console.log('Order created successfully:', data)
      return data
    } catch (error) {
      console.error('Error creating order:', error)
      setIsError(true)
      setError(error)
      throw error
    } finally {
      setIsLoading(false)
      clearCart()
    }
  }

  const fetchWilayas = async () => {
    try {
      const response = await axios.get('/wilayas')
      setWilayas(response.data)
    } catch (error) {
      console.error('Error fetching wilayas:', error)
    }
  }

  useEffect(() => {
    fetchWilayas()
  }, [])

  return (
    <OrderContext.Provider
      value={{
        createOrder,
        isLoading,
        isError,
        error,
        wilayas,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}
